import React from "react";

const defaultGlobalData = {
    messageDialog: null,
    setMessageDialog: () => {},

    inProgress: false,
    setInProgress: () => {},
};

export const GlobalContext = React.createContext(defaultGlobalData);