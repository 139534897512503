import React, {useEffect, useState} from "react";
import { GlobalContext } from "./GlobalContext";
import {fetchPositions} from "./FetchPositions";

export const GlobalContextProvider = ( { children } ) => {
    const [messageDialog, setMessageDialog] = useState(null);
    const [inProgress, setInProgress] = useState(false);
    const [fullInProgress, setFullInProgress] = useState(false);

    const [ positionData, setPositionData ] = useState(null);

    useEffect(() => {
        setInProgress(true);

        fetchPositions()
            .then( (newPositionData) => setPositionData(newPositionData) )
            .then( () => setInProgress(false) );
    }, []);

    return (
        <GlobalContext.Provider value={{ positionData, setPositionData, messageDialog, setMessageDialog, inProgress, setInProgress, fullInProgress, setFullInProgress }}>
            { children }
        </GlobalContext.Provider>
    );
};