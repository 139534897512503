
export const fetchPositions = () => {
    const publicPositions = true;
    const urlParams = new URLSearchParams(
        window === 'undefined' ? '' : window.location.search
    );
    const applySlug = urlParams.get('apply') || null;
    const additionalSlugs = applySlug ? [ applySlug ] : [];

    // build list of request(s)
    const positionsRequests = [];
    if (publicPositions) positionsRequests.push(new Request('/api/positions/'));
    if (Array.isArray(additionalSlugs)) {
        positionsRequests.push(
            ...additionalSlugs.map(
                (positionSlug) => new Request('/api/positions/' + positionSlug + '/get/')
            )
        );
    }

    const newPositionData = {
        positions: [],
        schools: [],
        summary: [],
    };

    return Promise.allSettled(
        positionsRequests.map( (positionsRequest) => {
            return fetch(positionsRequest)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Invalid response getting positions.  Response Status: " + response.status + " " + response.statusText);
                }

                const contentType = response.headers.get('content-type');
                if (!contentType || !contentType.includes('application/json')) {
                    throw new TypeError("Oops, we haven't got JSON!");
                }

                return response.json();
            })
            .then((data) => {
                if (data?.jobPostings?.length > 0 && data?.schools?.length > 0) {
                    newPositionData.positions = [...newPositionData.positions, ...data.jobPostings]
                        .sort((a, b) => {
                            if (a.ordering === b.ordering) {
                                return a.dueDate.localeCompare(b.dueDate);
                            }
                            return a.ordering - b.ordering;
                        });

                    newPositionData.schools = [...newPositionData.schools, ...data.schools];

                } else {
                    throw new Error("Positions or schools empty.");
                }
            })
            .catch((e) => {
                console.error("Unable to retrieve positions");
                console.error(e.message);
            });
        })
    ).then( () => {
        // filter and order positions
        newPositionData.positions = newPositionData.positions.filter(
            (p, i) =>
                newPositionData.positions.findIndex((pp) => pp.id === p.id) === i
        ).sort( (a, b) => {
            const x = additionalSlugs.includes(a.slug);
            const y = additionalSlugs.includes(b.slug);

            if (x === y) return 0;
            if (x && !y) return -1;
            return 1;
        });

        // filter schools
        newPositionData.schools = newPositionData.schools.filter(
            (s, i) =>
                newPositionData.schools.findIndex((ss) => ss.id === s.id) === i
        );

        // calc summary
        newPositionData.summary = getSummaryPositions(newPositionData.positions);
        return newPositionData;
    });
};

const getSummaryPositions = (positions) => {
    let summary= new Map();

    // add positions to schools
    positions.forEach( (position) => {
        if (!summary.has(position.schoolId)) {
            summary.set(position.schoolId, [ position ]);
            return;
        }

        // push to the array
        summary.get(position.schoolId).push(position);
    });

    return summary;
}
