import React from 'react';
import {GlobalContextProvider} from "./src/context/GlobalContextProvider";
import {fetchPositions} from "./src/context/FetchPositions";


require('typeface-raleway');
require('typeface-spectral');

export const wrapRootElement = ({ element }) => (
    <GlobalContextProvider>{element}</GlobalContextProvider>
);

export const onInitialClientRender = () => {
    fetchPositions();
};

